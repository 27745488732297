var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"border-bottom border-info rounded px-1 d-flex flex-wrap"},[_c('div',{staticClass:"col-lg-2 col-md-3 col-4 d-flex justify-content-center align-items-center py-3"},[('images' in _vm.reference)?_c('button',{staticClass:"w-28 h-28 p-0",on:{"click":function($event){return _vm.goToMainPage(_vm.reference.product.slug)}}},[_c('img',{staticClass:"w-100 h-100",attrs:{"src":_vm._f("image")(_vm.reference.images[0].url),"alt":_vm.reference.images[0].alt,"loading":"lazy"}})]):_c('img',{staticClass:"w-28 h-28 p-0",attrs:{"src":_vm.$data.$defaultImageUrl,"loading":"lazy"}})]),_c('div',{staticClass:"col-lg-10 col-md-9 col-8 d-flex flex-column justify-content-around py-3 px-1"},[_c('div',{staticClass:"d-flex justify-content-lg-between flex-lg-row flex-column align-items-center my-2"},[(_vm.reference.productId)?_c('div',{staticClass:"d-flex flex-column w-100"},[_c('router-link',{staticClass:"btn-a p-0",attrs:{"to":_vm.merchant
                                ? ("/app/inventories/" + (_vm.merchant.merchantType) + "/" + (_vm.merchant.merchantId) + "/detail/" + (_vm.reference.id))
                                : ("/app/inventories/detail/" + (_vm.reference.id))}},[_c('strong',[_vm._v(" "+_vm._s(_vm._f("cut")(_vm.reference.product.name,50))+"  ")])]),_c('div',[_c('small',[_vm._v(" Prod. "),_c('router-link',{staticClass:"btn-a p-0",attrs:{"to":("/app/products/detail/" + (_vm.reference.productId))}},[_vm._v(" "+_vm._s(_vm.reference.product.sku ? ("SKU " + (_vm.reference.product.sku)) : ("ID " + (_vm.reference.productId)))+" ")]),_vm._v(" | Ref. "),_c('router-link',{staticClass:"btn-a p-0",attrs:{"to":("/app/products/detail/" + (_vm.reference.productId) + "?reference=" + (_vm.reference.id) + "&active=references")}},[_vm._v(" "+_vm._s(_vm.reference.barcode ? ("Cód. " + (_vm.reference.barcode)) : ("ID " + (_vm.reference.id)))+" ")]),(
                                    _vm.reference.additionalConfigurations &&
                                    _vm.reference.additionalConfigurations
                                        .length
                                )?_c('fragment',[_vm._v(" | Tiene configuraciones extra ")]):_vm._e()],1)])],1):_c('div',{staticClass:"d-flex flex-column w-100"},[_c('span',{staticClass:"p-0"},[_vm._v(" "+_vm._s(_vm.reference.product.name)+" ")]),_c('small',[_vm._v(" Servicio / P.A. ")])]),_c('div',{staticClass:"d-flex w-100 justify-content-end align-items-center",attrs:{"id":"actions"}},[(_vm.enabled)?_c('fragment',[(_vm.enabledDiscount)?_c('vs-tooltip',{attrs:{"border":""},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Aplicar Descuento 🤑")]},proxy:true}],null,false,3199378999)},[_c('vs-checkbox',{attrs:{"warn":""},on:{"input":_vm.changeAvailableDiscount},model:{value:(_vm.availableDiscount),callback:function ($$v) {_vm.availableDiscount=$$v},expression:"availableDiscount"}})],1):_vm._e(),_c('b-form-spinbutton',{staticClass:"mx-2",attrs:{"max":"100","min":"1","size":"sm","inline":""},on:{"input":_vm.changeQuantity},scopedSlots:_vm._u([{key:"decrement",fn:function(){return [_vm._v("➖")]},proxy:true},{key:"increment",fn:function(){return [_vm._v("➕")]},proxy:true}],null,false,3273276492),model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('vs-button',{attrs:{"danger":"","circle":"","icon":""},on:{"click":_vm.remove}},[_vm._v(" 🗑 ")])],1):_vm._e(),(_vm.enabledDetails)?_c('vs-button',{attrs:{"circle":"","icon":""},on:{"click":_vm.openDetails}},[_vm._v(" 👀 ")]):_vm._e()],1)]),_c('div',{staticClass:"d-flex flex-column flex-lg-row flex-wrap justify-content-lg-between"},[_c('div',{staticClass:"d-flex flex-lg-column justify-content-between pr-2"},[_c('strong',{},[_vm._v("Color ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('small',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.reference.color)+" ")]),_c('div',{staticClass:"ml-1 len-3 rounded-circle border border-secondary",style:({
                                'background-color': _vm.reference.colorRef
                            })})])]),_c('div',{staticClass:"d-flex flex-lg-column justify-content-between pr-2"},[_c('strong',{},[_vm._v("Tamaño ")]),_c('small',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.reference.size)+" ")])]),_c('div',{staticClass:"d-flex flex-lg-column justify-content-between pr-2"},[_c('strong',{},[_vm._v("Cantidad ")]),_c('small',{class:{
                            'text-through font-weight-bold': _vm.quantity === 0
                        }},[_vm._v(" "+_vm._s(_vm.quantity)+" ")])]),_c('div',{staticClass:"d-flex flex-lg-column justify-content-between pr-2"},[_c('strong',{},[_vm._v("$ Unidad ")]),_c('small',[_vm._v(" "+_vm._s(_vm._f("money")(_vm.reference.priceOffer))+" ")])]),_c('div',{staticClass:"d-flex flex-lg-column justify-content-between pr-2"},[_c('strong',{},[_vm._v("$ Descuento u. ")]),_c('small',[_vm._v(" "+_vm._s(_vm._f("money")(_vm.reference.appliedDiscountPerReference))+" ")])]),(
                        _vm.reference.cost && Number.isInteger(_vm.reference.cost)
                    )?_c('div',{staticClass:"d-flex flex-lg-column justify-content-between pr-2"},[_c('strong',{},[_vm._v("Costo unidad ")]),_c('small',[_vm._v(" "+_vm._s(_vm._f("money")(_vm.reference.cost))+" ")])]):_vm._e(),_c('div',{staticClass:"w-100 mt-2 d-flex pr-2"},[_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(" $ Referencias:   ")]),_c('span',{staticClass:"font-weight-bold h5",class:{
                            'text-through ': _vm.quantity === 0
                        }},[_vm._v(" "+_vm._s(_vm._f("money")(((_vm.reference.priceOffer - (_vm.reference.appliedDiscountPerReference || 0)) * _vm.quantity)))+" ")])])]),(_vm.availableDiscount && _vm.enabled)?_c('div',{staticClass:"mt-4 pr-2"},[_c('input-money-component',{attrs:{"border":"","enabled":"","label":"Descuento neto por referencia","icon":"💲","message-error":_vm.appliedDiscountPerReference >
                        _vm.reference.priceOffer * 0.6
                            ? 'Descuento no debe exceder el 60% del valor de la referencia'
                            : ''},on:{"blur":_vm.blurAppliedDiscountPerReference,"focus":_vm.focusInput},model:{value:(_vm.appliedDiscountPerReference),callback:function ($$v) {_vm.appliedDiscountPerReference=$$v},expression:"appliedDiscountPerReference"}})],1):_vm._e()])]),_c('vs-dialog',{attrs:{"scroll":"","overflow-hidden":"","auto-width":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('h2',[_vm._v(" "+_vm._s(_vm.reference.product.name)+" ")])])]},proxy:true}]),model:{value:(_vm.isOpenDetails),callback:function ($$v) {_vm.isOpenDetails=$$v},expression:"isOpenDetails"}},[_c('div',{staticClass:"con-content"},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column col-12 col-md-6"},[_c('strong',{},[_vm._v("Color ")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.reference.color)+" ")]),_c('div',{staticClass:"ml-1 len-3 rounded-circle border border-secondary",style:({
                                'background-color': _vm.reference.colorRef
                            })})])]),_c('div',{staticClass:"d-flex flex-column col-12 col-md-6"},[_c('strong',{},[_vm._v("Tamaño/Talla/Valor ")]),_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.reference.size)+" ")])]),_c('div',{staticClass:"d-flex flex-column col-12 col-md-6"},[_c('strong',{},[_vm._v("Cantidad ")]),_c('span',[_vm._v(" "+_vm._s(_vm.quantity)+" ")])]),_c('div',{staticClass:"d-flex flex-column col-12 col-md-6"},[_c('strong',{},[_vm._v("Precio por unidad ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.reference.priceOffer))+" ")])]),_c('div',{staticClass:"d-flex flex-column col-12 col-md-6"},[_c('strong',{},[_vm._v("Valor de descuento por unidad ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.reference.appliedDiscountPerReference))+" ")])]),_c('div',{staticClass:"d-flex flex-column col-12 col-md-6"},[_c('strong',{},[_vm._v(" Costo unidad en el momento de la orden ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.reference.cost))+" ")])]),_c('div',{staticClass:"d-flex flex-column col-12 col-md-6"},[_c('strong',[_vm._v(" Precio de referencias: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(((_vm.reference.priceOffer - (_vm.reference.appliedDiscountPerReference || 0)) * _vm.quantity)))+" ")])]),_c('div',{staticClass:"col-12"},[(
                            _vm.reference.additionalConfigurations &&
                            _vm.reference.additionalConfigurations.length
                        )?_c('div'):_c('strong',{staticClass:"mt-3"},[_vm._v(" No tiene configuraciones adicionales ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }