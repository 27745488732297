<template>
    <div
        class="d-flex flex-column col-12 col-md-11 bg-custom border"
        :class="{ 'dark-mode': activeDark }"
    >
        <div class="py-2 calendar-row">
            <div v-for="weekday in weekdays" :key="weekday">
                <strong class="h4">
                    {{ weekday }}
                </strong>
            </div>
        </div>
        <div class="calendar-row">
            <button
                v-for="day in days"
                :key="day.date"
                class="btn text-base p-lg-4 p-md-3 px-1 py-4 position-relative"
                :class="{
                    'btn-success': day.isToday,
                    'other-month bg-transparent': day.isOtherMonth,
                    'text-secondary bg-transparent': day.isDisabled,
                    'border-2 border btn-primary': day.itemsNumber
                }"
                @click="selectDay(day.date)"
            >
                <small
                    v-if="day.itemsNumber"
                    class="position-absolute top-1 right-1 translate-middle badge rounded-pill bg-danger"
                >
                    {{ day.itemsNumber }}
                </small>
                <span>
                    {{ day.day }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";

import { TIMEZONE } from "@/common/constants";

export default {
    components: {},
    props: {
        startDate: {
            type: String,
            required: true
        },
        endDate: {
            type: String,
            required: true
        },
        activeDark: {
            type: Boolean,
            default: false
        },
        itemsByDate: {
            type: Object,
            default: () => ({}),
            required: true
        }
    },
    data() {
        return {
            weekdays: ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"],
            days: []
        };
    },
    computed: {},
    watch: {
        itemsByDate() {
            this.generateCalendar();
        },
        startDate() {
            this.generateCalendar();
        }
    },
    mounted() {
        this.generateCalendar();
    },
    methods: {
        buildItemsNumber(date) {
            if (date in this.itemsByDate) {
                return this.itemsByDate[date].length;
            }
            return 0;
        },
        generateCalendar() {
            this.days = [];
            const startDateIso = DateTime.fromISO(this.startDate).setZone(
                TIMEZONE
            );
            const endDateIso = DateTime.fromISO(this.endDate).setZone(TIMEZONE);
            const startOfMonth = startDateIso.startOf("month");
            const endOfMonth = startDateIso.endOf("month");

            const lastDay = endOfMonth.setZone(TIMEZONE).endOf("week");
            const now = DateTime.now();
            let currentDay = startOfMonth.setZone(TIMEZONE).startOf("week");
            while (currentDay <= lastDay) {
                const isDisabled =
                    currentDay < startDateIso || currentDay > endDateIso;
                const date = currentDay.toISODate();
                const itemsNumber = this.buildItemsNumber(date);
                this.days.push({
                    date,
                    day: currentDay.day,
                    isToday: currentDay.hasSame(now, "day"),
                    isOtherMonth: !currentDay.hasSame(startDateIso, "month"),
                    isDisabled: isDisabled,
                    itemsNumber
                });
                currentDay = currentDay.plus({ days: 1 });
            }
        },
        selectDay(date) {
            if (!this.days.find((day) => day.date === date).isDisabled) {
                this.$emit("day-click", date);
            }
        }
    }
};
</script>
