<template>
    <div class="my-3">
        <div class="col-12 col-lg-11 mx-auto">
            <h1 class="text-center">Calendario de Entregas</h1>

            <p class="mt-3">
                Por defecto el sistema consulta las ordenes en curso, es decir
                una orden que esta en un estado de entrega diferente al
                entregado o cancelado.
            </p>
        </div>

        <div
            class="d-flex d-md-none justify-content-center mt-4 flex-column align-items-center"
        >
            <h3 class="text-capitalize">
                {{ currentMonth }}
            </h3>
            <span class=""> Ordenes encontradas {{ itemsNumber }} </span>
        </div>
        <div class="d-flex justify-content-around mt-2 mt-md-0">
            <button class="btn btn-primary" @click="previousMonth">
                Mes anterior
            </button>
            <div class="d-none d-md-flex flex-column align-items-center">
                <h3 class="text-capitalize">
                    {{ currentMonth }}
                </h3>
                <span class=""> Ordenes encontradas {{ itemsNumber }} </span>
            </div>

            <button class="btn btn-primary" @click="nextMonth">
                Mes siguiente
            </button>
        </div>
        <div class="d-flex justify-content-center mt-4 mb-5">
            <order-delivery-calendar
                :items-by-date="ordersGroupByDeliveryAt"
                :start-date="startDate"
                :end-date="endDate"
                :dark="activeDark"
                @day-click="handleDayClick"
            />
        </div>
        <vs-dialog
            v-model="isOpenDetailsModal"
            scroll
            overflow-hidden
            full-screen
        >
            <template #header>
                <div class="d-flex flex-column align-items-center mt-4">
                    <h2 class="">
                        {{ ordersSelected.length > 1 ? "Ordenes" : "Orden" }}

                        para entregar el
                        <span class="text-primary">
                            {{ dateSelected }}
                        </span>
                    </h2>
                    <h3>Total {{ ordersSelected.length }}</h3>
                </div>
            </template>
            <div class="con-content mt-2">
                <div v-for="order in ordersSelected" :key="order.id">
                    <div class="mt-2">
                        <h3 class="text-primary text-center">
                            Orden
                            <a
                                :href="`/app/orders/detail/${order.id}`"
                                target="_blank"
                                rel="noopener"
                                class="text-center font-weight-normal"
                            >
                                #{{ order.id }}
                            </a>
                        </h3>
                        <div class="d-flex flex-wrap my-3">
                            <div v-if="order.customer" class="col-12 mb-2">
                                <div>
                                    <div>
                                        <strong> Datos del cliente </strong>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="col-6 d-flex">
                                            <strong>Nombre:</strong>
                                            <span>
                                                &nbsp;
                                                {{ order.customer.firstName }}
                                                {{ order.customer.lastName }}
                                            </span>
                                        </div>
                                        <div class="col-6 d-flex">
                                            <strong>Teléfono:</strong>
                                            <span>
                                                &nbsp;
                                                {{ order.customer.phone }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-md-4 d-flex flex-column">
                                <strong> Estado </strong>
                                <span
                                    :class="
                                        $data.$deliveryStatusesMessageClass[
                                            order.deliveryStatus
                                        ]
                                    "
                                >
                                    {{ order.deliveryStatusMessage }}
                                </span>
                            </div>
                            <div class="col-6 col-md-4 d-flex flex-column">
                                <strong> Costo de envío </strong>
                                <span>{{ order.deliveryPrice | money }}</span>
                            </div>
                            <div class="col-6 col-md-4 d-flex flex-column">
                                <strong>Total</strong>
                                <span>{{ order.total | money }}</span>
                            </div>
                            <div class="col-6 col-md-4 d-flex flex-column">
                                <strong> Estado del pago </strong>
                                <span
                                    :class="
                                        $data.$paymentStatusesMessageClass[
                                            order.paymentStatus
                                        ]
                                    "
                                    >{{ order.paymentStatusMessage }}</span
                                >
                            </div>

                            <div class="col-6 col-md-4 d-flex flex-column">
                                <strong> Fecha de creación </strong>
                                <span>{{ order.createdAt | date }}</span>
                            </div>
                            <div class="col-6 col-md-4 d-flex flex-column">
                                <strong> Fecha de entrega</strong>
                                <span>{{ order.deliveryAt | dateSimple }}</span>
                            </div>
                        </div>
                        <order-reference-table-item
                            v-for="(reference, index) in order.references"
                            :key="index"
                            :reference="reference"
                            :enabled-details="false"
                            class="col-12 mx-auto mt-1"
                        />
                    </div>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapState } from "vuex";

import { deliveryStatusesMessageClass } from "@/common/fields/delivery-status-codes";
import { paymentStatusesMessageClass } from "@/common/fields/payment-status-codes";

import OrderDeliveryCalendar from "@/components/orders/OrderDeliveryCalendar.vue";
import OrderReferenceTableItem from "@/components/orders/OrderReferenceTableItem.vue";

export default {
    name: "CalendarOrders",
    components: { OrderDeliveryCalendar, OrderReferenceTableItem },
    layout: "app",
    meta: {
        roles: ["administrator", "support"]
    },
    data: () => ({
        loaderInstance: null,
        $deliveryStatusesMessageClass: deliveryStatusesMessageClass,
        $paymentStatusesMessageClass: paymentStatusesMessageClass,
        ordersGroupByDeliveryAt: {},
        ordersSelected: [],
        itemsNumber: 0,
        currentMonth: "Enero",
        startDate: "2025-02-01",
        endDate: "2023-02-28",
        dateSelected: "",
        isOpenDetailsModal: false
    }),
    computed: {
        ...mapState("control", ["activeDark"]),
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {},
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if (!(await verifyPageScope("order:List", "/app"))) return;
        const now = DateTime.local();
        this.currentMonth = now.toLocaleString({
            month: "long",
            year: "numeric"
        });
        this.startDate = now.startOf("month").toISODate();
        this.endDate = now.endOf("month").toISODate();
        this.listItems();
    },
    methods: {
        ...mapActions("orders", ["listOrdersSchedule"]),
        async listItems() {
            this.ordersGroupByDeliveryAt = {};
            this.ordersSelected = [];
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const { items } = await this.listOrdersSchedule({
                    begin: this.startDate,
                    end: this.endDate
                });
                let objectAux = {};
                items.forEach((item) => {
                    const deliveryAt = DateTime.fromISO(item.deliveryAt);
                    const deliveryAtFormat = deliveryAt.toISODate();
                    if (!(deliveryAtFormat in objectAux)) {
                        objectAux[deliveryAtFormat] = [item];
                    } else {
                        console.log(objectAux);
                        objectAux[deliveryAtFormat].push(item);
                    }
                });
                this.ordersGroupByDeliveryAt = objectAux;
                this.itemsNumber = items.length;
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
            }
        },
        async reloadItems() {
            await this.listItems();
        },
        handleDayClick(date) {
            this.dateSelected = date;
            this.ordersSelected = this.ordersGroupByDeliveryAt[date];
            this.isOpenDetailsModal = true;
        },
        async previousMonth() {
            const newStartDate = DateTime.fromISO(this.startDate).minus({
                months: 1
            });
            this.currentMonth = newStartDate.toLocaleString({
                month: "long",
                year: "numeric"
            });
            this.startDate = newStartDate.toISODate();
            this.endDate = newStartDate.endOf("month").toISODate();
            await this.listItems();
        },
        async nextMonth() {
            const newStartDate = DateTime.fromISO(this.startDate).plus({
                months: 1
            });
            this.currentMonth = newStartDate.toLocaleString({
                month: "long",
                year: "numeric"
            });
            this.startDate = newStartDate.toISODate();
            this.endDate = newStartDate.endOf("month").toISODate();
            await this.listItems();
        }
    }
};
</script>
